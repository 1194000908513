import PropTypes from "prop-types";
import React from "react";

const ApplicationButton = ({
  iconFirst = false,
  name = "",
  onClick = () => {},
  icon = <></>,
}) => {
  return (
    <button type="button" onClick={onClick} className="application-button">
      {iconFirst && <div className="application-button-icon">{icon}</div>}
      <div className="application-button-title">{name}</div>
      {!iconFirst && (
        <div className="application-button-icon call-to-action-button__icon">
          {icon}
        </div>
      )}
    </button>
  );
};

ApplicationButton.propTypes = {
  icon: PropTypes.element,
  iconFirst: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string,
};

export default ApplicationButton;
